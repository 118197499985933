import React, { useState, useEffect, useContext, useCallback } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import FancyBox from "../../customComponents/FancyBox";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import VerticalSplitOutlinedIcon from "@material-ui/icons/VerticalSplitOutlined";
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import SubjectOutlinedIcon from "@material-ui/icons/SubjectOutlined";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import SwapVertOutlinedIcon from "@material-ui/icons/SwapVertOutlined";
import ViewModuleOutlinedIcon from "@material-ui/icons/ViewModuleOutlined";
import CollectionCard from "../../customComponents/CollectionCard";
import CustomSelect from "../../customComponents/CustomSelect";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import CustomTooltip from "../../customComponents/CustomTooltip";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from '@material-ui/icons/Warning';
import DateCountdown from "react-date-countdown-timer";
import ReportModal from "./ReportModal";
import {
  fetchSingleNftItemsStart,
  moreFromCollectionStart,
  nftItemsPaymentListStart,
  nftItemFavouriteUsersListStart,
  nftFavouritesSaveStart,
  loadMoreItemActivityStart,
  clearNftSingleSellData,
  clearCancelListingData,
  clearNftBuyData,
  buyNftItemStart,
  cancelListNftItemStart,
  clearTransferNftData,
  clearNftSingleImageData,
  bidOfferNftItemListStart,
  bidOfferNftItemSaveStart,
  acceptOfferNftItemStart,
  cancelBidOfferNftItemStart,
} from "../../store/actions/NftAction";
import { Button, Dropdown } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/ToastNotification";
import { authContext } from "../authprovider/AuthProvider";
import CancelListingModal from "../Listing/CancelListingModal";
import UnReviewedModal from "../Listing/UnReviewedModal";
import MakeOfferModal from "../Modals/MakeOfferModal";
import FavoritedByModal from "../Collection/FavoritedByModal";
import Activitytable from "../../customComponents/Activitytable";
import CustomInfiniteScroll from "../../customComponents/CustomInfiniteScroll";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PageNotFound from "../../components/404/PageNotFound";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import SingleImageLoader from "../loaders/SingleImageLoader";
import NFTMainContract from "../../abis/NFTMainContract.json";
import Token from "../../abis/Token.json";
import SellerContract from "../../abis/MarketPlaceContract.json";
import configuration from "react-global-configuration";
import usePriceImage, { networkLogo, stableTokenLight } from "../priceImages/PriceImage";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import { useTheme } from "@material-ui/core";
import TransferModal from "../Modals/TransferModal";
import useValidation from "../../customHooks/useValidation";
import NftToken from "../../abis/NftToken.json";
import Web3 from "web3";
import SingleImageItemListLoader from "../loaders/SingleImageItemListLoader";
import OfferListTable from "../../customComponents/OfferListTable";
import SinglePageActivityTableLoader from "../loaders/SinglePageActivityTableLoader";
import MarketPlaceContract from "../../abis/MarketPlaceContract.json";
import moment from "moment";

const queryString = require("query-string");

const ethPrice = require("eth-price");

const SingleNftImage = (props) => {
  const { auth, loginConnectors, handleConnector, price } =
    useContext(authContext);

  const { contract_address, token_id } = useParams();

  const theme = useTheme();

  const priceImage = usePriceImage(theme.palette.theme);

  const location = useLocation();

  const history = useHistory();

  const netID = configuration.get("configData.network_id")
    ? configuration.get("configData.network_id")
    : "97";

  const nft_collection_item_unique_id =
    !props.singleNftImage.loading &&
    props.singleNftImage.data.nft_collection_item
      ? props.singleNftImage.data.nft_collection_item
          .nft_collection_item_unique_id
      : "";

  const sellerContractAddress = configuration.get(
    "configData.seller_contract_address"
  )
    ? configuration.get("configData.seller_contract_address")
    : "0xd9151fd753aC3ac9Afd82b80EE8B3D935B1091D0";

  const [priceSelect, setPriceSelect] = useState([
    configuration.get("configData.native_currency_symbol"),
  ]);

  const [cancelListingModal, setCancelListingModal] = useState(false);

  const [favouriteStatus, setFavouriteStatus] = useState(false);

  const [favouritesCount, setFavouritesCount] = useState(0);

  const [usdPrice, setUsdPrice] = useState(0);

  const [nftContractData, setNftContractData] = useState("");

  const [sellerContract, setSellerContract] = useState("");

  const [buttonContent, setButtonContent] = useState("");

  const [cancelListingButtonContent, setCancelListingButtonContent] =
    useState("");

  const [transferModal, setTranseferModal] = useState({
    status: false,
  });

  const [activeId, setActiveId] = useState("4");

  const [priceSort, setPriceSort] = useState([]);

  const [signData, setSignData] = useState({
    signature: null,
    status: false,
    loading: false,
    loadingContent: null,
  });

  const [makeOfferButtonContent, setMakeOfferButtonContent] = useState("");

  const [cancelOfferBtnContent, setCancelOfferBtnContent] = useState("");

  const [acceptOfferBtnContent, setAcceptOfferBtnContent] = useState("");

  const [activeCancelListingAccordion, setActiveCancelListingAccordion] =
    useState("1");

  const [activeTransferAccordion, setActiveTransferAccordion] = useState("1");

  const [currentOwner, setCurrentOwner] = useState(null);

  const toggleActiveAccordion = (id) => {
    setActiveCancelListingAccordion(id);
  };

  const toggleActiveTransferAccordion = (id) => {
    setActiveTransferAccordion(id);
  };

  const handleCancelListingOpen = () => setCancelListingModal(true);
  const handleCancelListingClose = () => setCancelListingModal(false);

  useEffect(() => {
    props.dispatch(clearNftSingleImageData());
    props.dispatch(clearNftSingleSellData());
    props.dispatch(clearCancelListingData());
    getCurrentOwner();
  }, [token_id]);

  const { validation } = useValidation(props.singleNftImage);

  const getCurrentOwner = async () => {
    let currentowneraddress = await getOwner(contract_address, token_id).then(
      (res) => res
    );
    setCurrentOwner(currentowneraddress);
    props.dispatch(
      fetchSingleNftItemsStart({
        nft_collection_contract_address: contract_address,
        nft_collection_item_token_id: token_id,
        owner_wallet_address:
          currentowneraddress == null || currentowneraddress == false
            ? ""
            : currentowneraddress,
        current_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );
  };

  useEffect(() => {
    if (!auth.loading && auth.accounts != "" && auth.authStatus) {
      getCurrentOwner();
    }
  }, [auth.authStatus]);

  useEffect(() => {
    if (!validation.loading && !validation.status) {
      props.dispatch(
        moreFromCollectionStart({
          nft_collection_contract_address: contract_address,
        })
      );
      props.dispatch(
        nftItemsPaymentListStart({
          nft_collection_item_token_id: token_id,
          nft_collection_contract_address: contract_address,
          skip: 0,
          take: 6,
        })
      );
      props.dispatch(
        nftItemFavouriteUsersListStart({
          nft_collection_contract_address: contract_address,
          nft_collection_item_token_id: token_id,
        })
      );
      props.dispatch(
        bidOfferNftItemListStart({
          nft_collection_contract_address: contract_address,
          nft_collection_item_token_id: token_id,
        })
      );
    }
  }, [validation]);

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const handlePriceSortChange = (event) => {
    const {
      target: { value },
    } = event;

    setPriceSort(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    props.dispatch(
      nftItemsPaymentListStart({
        nft_collection_item_token_id: token_id,
        nft_collection_contract_address: contract_address,
        payment_type: value === "string" ? value.split(",") : value,
      })
    );
  };

  const handleRemovePriceSort = (value) => {
    var payment_type = priceSort;
    payment_type.splice(payment_type.indexOf(value), 1);

    setPriceSort(payment_type);
    props.dispatch(
      nftItemsPaymentListStart({
        nft_collection_item_token_id: token_id,
        nft_collection_contract_address: contract_address,
        payment_type:
          payment_type === "string" ? payment_type.split(",") : payment_type,
      })
    );
  };

  const [open, setOpen] = useState(false);

  const handleReportClose = () => setOpen(false);
  const handleReportOpen = () => setOpen(true);

  const [unReviewedModal, setUnReviewedModal] = useState(false);

  const handleUnReviewedClose = () => setUnReviewedModal(false);
  const handleUnReviewedOpen = () => setUnReviewedModal(true);

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      "Link was copied to clipboard!"
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const [favoiriteUsersModal, setFavoiriteUserModal] = useState(false);

  const handlefavouriteUsersOpen = () => setFavoiriteUserModal(true);
  const handlefavouriteUsersClose = () => setFavoiriteUserModal(false);

  const [makeOfferModal, setMakeOfferModal] = useState(false);

  const handleMakeOfferClose = () => {
    props.dispatch(
      bidOfferNftItemListStart({
        nft_collection_contract_address: contract_address,
        nft_collection_item_token_id: token_id,
      })
    );
    setMakeOfferModal(false);
  };
  const handleMakeOfferOpen = () => setMakeOfferModal(true);

  useEffect(() => {
    if (
      !props.favouriteSave.loading &&
      props.singleNftImage.data.nft_collection_item
    ) {
      if (
        props.favouriteSave.data.data.token_id ==
        props.singleNftImage.data.nft_collection_item.token_id
      ) {
        if (props.favouriteSave.data.code == "114") {
          setFavouritesCount(favouritesCount + 1);
          setFavouriteStatus(true);
        } else {
          setFavouritesCount(favouritesCount - 1 < 0 ? 0 : favouritesCount - 1);
          setFavouriteStatus(false);
        }
      }
      props.dispatch(
        nftItemFavouriteUsersListStart({
          nft_collection_contract_address: contract_address,
          nft_collection_item_token_id:
            props.singleNftImage.data.nft_collection_item.token_id,
        })
      );
    }
  }, [props.favouriteSave.data]);

  const handleLike = () => {
    props.dispatch(
      nftFavouritesSaveStart({
        nft_collection_contract_address: contract_address,
        nft_collection_item_token_id:
          props.singleNftImage.data.nft_collection_item.token_id,
      })
    );
  };

  useEffect(() => {
    if (
      !props.singleNftImage.loading &&
      props.singleNftImage.data.nft_collection_item
    ) {
      if (!auth.loading && auth.accounts != "" && auth.authStatus)
        connectBlockchain();
      setFavouriteStatus(
        props.singleNftImage.data.nft_collection_item.is_favourite_item
          ? true
          : false
      );
      setFavouritesCount(
        props.singleNftImage.data.nft_collection_item.favourites_count
      );
      ethPrice("usd").then((data) => {
        const usdPrice = data.toString();
        const usdPriceFormatted = usdPrice.slice(5, usdPrice.length);
        setUsdPrice(usdPriceFormatted);
      });
      setPriceSelect(props.singleNftImage.data.nft_collection_item.list_amount_type ? 
        props.singleNftImage.data.nft_collection_item.list_amount_type == 1 ? 
        configuration.get("configData.native_currency_symbol").split(",") : 
        configuration.get("configData.stable_toke_symbol").split(",") : 
        configuration.get("configData.native_currency_symbol").split(","));
    }
  }, [props.singleNftImage.data]);

  // Blockchain code.

  // Get NFT data from the blockchain.
  const connectBlockchain = async () => {
    // NFT contract.
    const web3 = window.web3;
    let nftContractData;
    const NFTData = NftToken.networks[netID];
    let sellerContract;
    if (NFTData) {
      nftContractData = await new web3.eth.Contract(
        NftToken.abi,
        contract_address
      );
      sellerContract = await new web3.eth.Contract(
        SellerContract.abi,
        configuration.get("configData.seller_contract_address")
      );
      setSellerContract(sellerContract);
      setNftContractData(nftContractData);
    } else {
      window.alert("NFT contract not deployed to detected network.");
    }
  };

  // Cancel blockchain code.

  const cancelBlockchain = async () => {
    setCancelListingButtonContent("Canceling please wait...");

    sellerContract.methods
      .withdrawSellOffer(token_id, contract_address)
      .send({
        from: auth.accounts,
      })
      .on("receipt", (receipt) => {
        console.log("Trans", receipt);
        // Api
        setCancelListingButtonContent("");
        props.dispatch(
          cancelListNftItemStart({
            nft_collection_item_unique_id:
              props.singleNftImage.data.nft_collection_item
                .nft_collection_item_unique_id,
          })
        );
      })
      .on("error", (error) => {
        setCancelListingButtonContent("");
        console.log("Error", error);
        let notificationMessage;
        if (error.message == undefined) {
          notificationMessage = getErrorNotificationMessage(
            "Unexpected error occuried, Please try again..."
          );
          props.dispatch(createNotification(notificationMessage));
        } else {
          notificationMessage = getErrorNotificationMessage(error.message);
          props.dispatch(createNotification(notificationMessage));
        }
        // Send error notification.
      });
  };

  // transfer money from buyer account to seller account and once the amount is received then we need to send the NFT to the buyer account

  const sendMoneyToCreator = async () => {
    setButtonContent("Initiated...");
    const web3 = window.web3;
    const seller_wallet_address =
      !props.singleNftImage.loading &&
      props.singleNftImage.data.nft_collection_item.recent_buyer
        ? props.singleNftImage.data.nft_collection_item.recent_buyer
            .wallet_address
        : "";

    let USDVToken = new web3.eth.Contract(
      Token.abi,
      "0xcD7509b76281223f5B7d3aD5d47F8D7Aa5C2B9bf"
    );

    const stableTokenAmount =
      props.singleNftImage.data.nft_collection_item.stable_token_amount.toString();

    let stable_token_amount =
      priceSelect[0] == configuration.get("configData.stable_toke_symbol")
        ? window.web3.utils.toWei(stableTokenAmount, "Ether")
        : 0;

    console.log("stable_token_amount ", stable_token_amount);
    console.log("stableTokenAmount ", stableTokenAmount);

    if (seller_wallet_address != "") {
      setButtonContent((prevState) => "Loading...");

      const paymentAmount =
        props.singleNftImage.data.nft_collection_item.amount.toString();

      if (
        priceSelect[0] == configuration.get("configData.stable_toke_symbol")
      ) {
        USDVToken.methods
          .approve(
            configuration.get("configData.seller_contract_address"),
            stable_token_amount
          )
          .send({ from: auth.accounts })
          .on("receipt", (receipt) => {
            sellerContract.methods
              .purchase(token_id, contract_address, stable_token_amount)
              .send({
                from: auth.accounts,
                value: 0,
              })
              .on("receipt", (receipt) => {
                console.log("Trans", receipt);
                props.dispatch(
                  buyNftItemStart({
                    nft_collection_item_unique_id:
                      nft_collection_item_unique_id,
                    transaction_hash:
                      receipt && receipt.transactionHash
                        ? receipt.transactionHash
                        : "",
                    contract_address: contract_address,
                    price_type: configuration.get("configData.stable_toke_symbol"),
                  })
                );
              })
              .on("error", (error) => {
                setButtonContent("");
                console.log("Error", error);
                let notificationMessage;
                if (error.message == undefined) {
                  notificationMessage = getErrorNotificationMessage(
                    "Unexpected error occuried, Please try again..."
                  );
                  props.dispatch(createNotification(notificationMessage));
                } else {
                  notificationMessage = getErrorNotificationMessage(
                    error.message
                  );
                  props.dispatch(createNotification(notificationMessage));
                }
                // Send error notification.
              });
          })
          .on("error", (error) => {
            setButtonContent("");
            console.log("Error", error);
            let notificationMessage;
            if (error.message == undefined) {
              notificationMessage = getErrorNotificationMessage(
                "Unexpected error occuried, Please try again..."
              );
              props.dispatch(createNotification(notificationMessage));
            } else {
              notificationMessage = getErrorNotificationMessage(error.message);
              props.dispatch(createNotification(notificationMessage));
            }
            // Send error notification.
          });
      } else {
        sellerContract.methods
          .purchase(token_id, contract_address, stable_token_amount)
          .send({
            from: auth.accounts,
            value: window.web3.utils.toWei(paymentAmount, "Ether"),
          })
          .on("receipt", (receipt) => {
            console.log("Trans", receipt);
            props.dispatch(
              buyNftItemStart({
                nft_collection_item_unique_id:
                  nft_collection_item_unique_id,
                transaction_hash:
                  receipt && receipt.transactionHash
                    ? receipt.transactionHash
                    : "",
                contract_address: contract_address,
                price_type: configuration.get("configData.native_currency_symbol"),
              })
            );
          })
          .on("error", (error) => {
            setButtonContent("");
            console.log("Error", error);
            let notificationMessage;
            if (error.message == undefined) {
              notificationMessage = getErrorNotificationMessage(
                "Unexpected error occuried, Please try again..."
              );
              props.dispatch(createNotification(notificationMessage));
            } else {
              notificationMessage = getErrorNotificationMessage(error.message);
              props.dispatch(createNotification(notificationMessage));
            }
            // Send error notification.
          });
      }
    } else {
      setButtonContent("");
      // Send fail notification.
      const notificationMessage = getErrorNotificationMessage(
        "Seller is Unavailable."
      );
      props.dispatch(createNotification(notificationMessage));
      // window.alert("Seller is Unavailable.");
    }
  };

  useEffect(() => {
    if (buttonContent != "") {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [buttonContent]);

  useEffect(() => {
    if (
      !props.buyNftItem.loading &&
      props.buyNftItem.data.code == 121 &&
      props.singleNftImage.data.nft_collection_item
    ) {
      handleUnReviewedClose();
      props.dispatch(clearNftSingleSellData());
      props.dispatch(clearCancelListingData());
      // props.dispatch(
      //   fetchSingleNftItemsStart({
      //     nft_collection_contract_address: contract_address,
      //     nft_collection_item_token_id: token_id,
      //     owner_wallet_address : currentOwner
      //   })
      // );
      getCurrentOwner();
      props.dispatch(
        nftItemsPaymentListStart({
          nft_collection_item_token_id: token_id,
          nft_collection_contract_address: contract_address,
          skip: 0,
          take: 6,
        })
      );
    }
  }, [props.buyNftItem.data]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const handleTransferModalOpen = () => {
    setTranseferModal({
      ...transferModal,
      status: true,
    });
  };

  const handleTransferModalClose = () => {
    setTranseferModal({
      ...transferModal,
      status: false,
    });
  };

  useEffect(() => {
    handleTransferModalClose();
    handlefavouriteUsersClose();
    handleCancelListingClose();
    handleReportClose();
    handlefavouriteUsersClose();
  }, [auth.accounts]);

  const handlePropertySelect = (property) => {
    const query = queryString.stringify(
      {
        property: [
          JSON.stringify({ name: property.name, value: [property.value] }),
        ],
      },
      { arrayFormat: "bracket-separator", arrayFormatSeparator: "|" }
    );

    history.push({
      pathname: `/collection/${contract_address}`,
      search: query,
    });
  };

  useEffect(() => {
    if (
      !props.transferNft.loading &&
      Object.keys(props.transferNft.data).length > 0
    ) {
      // props.dispatch(
      //   fetchSingleNftItemsStart({
      //     nft_collection_contract_address: contract_address,
      //     nft_collection_item_token_id: token_id,
      //   })
      // );
      setSignData({
        ...signData,
        signature: null,
        status: false,
        loading: false,
        loadingContent: null,
      });
      getCurrentOwner();
      props.dispatch(clearTransferNftData());
      handleTransferModalClose();
    }
  }, [props.transferNft.loading]);

  const getOwner = async (contract_address, token_id) => {
    const web3 = new Web3(Web3.givenProvider || "http://localhost:3000");
    let nftContractData;
    let ownerAddress;
    const NFTData = NftToken.networks[netID];
    if (NFTData) {
      try {
        nftContractData = await new web3.eth.Contract(
          NftToken.abi,
          contract_address
        );
        ownerAddress = await nftContractData.methods.ownerOf(token_id).call();
        return ownerAddress;
      } catch (error) {
        console.log(error);
        return false;
      }
    }
  };

  const makeOfferSave = async (offerData) => {
    let bidExpire = `${moment(offerData.bid_expire_at).format("YYYY-MM-DD")} ${
      offerData.endTime
    }:00`;

    setMakeOfferButtonContent("Initiated...");

    const web3 = window.web3;
    let marketPlaceContractData;

    marketPlaceContractData = await new web3.eth.Contract(
      MarketPlaceContract.abi,
      sellerContractAddress
    );

    bidExpire = Date.parse(bidExpire) / 1000;

    const paymentAmount = offerData.amount.toString();

    try {
      marketPlaceContractData.methods
        .makeBuyOffer(token_id, contract_address, bidExpire)
        .send({
          from: auth.accounts,
          value: window.web3.utils.toWei(paymentAmount, "Ether"),
        })
        .on("receipt", (receipt) => {
          props.dispatch(
            bidOfferNftItemSaveStart({
              nft_collection_contract_address: contract_address,
              nft_collection_item_token_id: token_id,
              amount: offerData.amount,
              bid_expire_at: `${moment(offerData.bid_expire_at).format(
                "YYYY-MM-DD"
              )} ${offerData.endTime}:00`,
            })
          );
          // Send success notification.
        })
        .on("error", (error) => {
          let errorMessage;
          if (error.message == undefined) {
            errorMessage = "Unexpected error occuried, Please try again...";
          } else {
            errorMessage = error.message;
          }
          let notificationMessage = getErrorNotificationMessage(errorMessage);
          props.dispatch(createNotification(notificationMessage));
          setMakeOfferButtonContent("");
          // Send error notification.
        });
    } catch (error) {
      let notificationMessage = getErrorNotificationMessage(
        "Something went wrong"
      );
      props.dispatch(createNotification(notificationMessage));
      setMakeOfferButtonContent("");
    }
  };

  const approveOfferSave = async (acceptOfferInput) => {
    setAcceptOfferBtnContent("Initiated...");

    const web3 = window.web3;
    let marketPlaceContractData;

    marketPlaceContractData = await new web3.eth.Contract(
      MarketPlaceContract.abi,
      sellerContractAddress
    );

    try {
      marketPlaceContractData.methods
        .acceptBuyOffer(token_id, contract_address)
        .send({ from: auth.accounts })
        .on("receipt", (receipt) => {
          props.dispatch(acceptOfferNftItemStart(acceptOfferInput));
          // Send success notification.
        })
        .on("error", (error) => {
          let errorMessage;
          if (error.message == undefined) {
            errorMessage = "Unexpected error occuried, Please try again...";
          } else {
            errorMessage = error.message;
          }
          let notificationMessage = getErrorNotificationMessage(errorMessage);
          props.dispatch(createNotification(notificationMessage));
          setAcceptOfferBtnContent("");
          // Send error notification.
        });
    } catch (error) {
      let notificationMessage = getErrorNotificationMessage(
        "Something went wrong"
      );
      props.dispatch(createNotification(notificationMessage));
      setAcceptOfferBtnContent("");
    }
  };

  const cancelOfferSave = async (cancelOfferInput) => {
    setCancelOfferBtnContent("Initiated...");

    const web3 = window.web3;
    let marketPlaceContractData;

    marketPlaceContractData = await new web3.eth.Contract(
      MarketPlaceContract.abi,
      sellerContractAddress
    );

    try {
      marketPlaceContractData.methods
        .withdrawBuyOffer(token_id, contract_address)
        .send({ from: auth.accounts })
        .on("receipt", (receipt) => {
          props.dispatch(cancelBidOfferNftItemStart(cancelOfferInput));
          // Send success notification.
        })
        .on("error", (error) => {
          let errorMessage;
          if (error.message == undefined) {
            errorMessage = "Unexpected error occuried, Please try again...";
          } else {
            errorMessage = error.message;
          }
          let notificationMessage = getErrorNotificationMessage(errorMessage);
          props.dispatch(createNotification(notificationMessage));
          setCancelOfferBtnContent("");
          // Send error notification.
        });
    } catch (error) {
      let notificationMessage = getErrorNotificationMessage(
        "Something went wrong"
      );
      props.dispatch(createNotification(notificationMessage));
      setCancelOfferBtnContent("");
    }
  };

  return (
    <>
      {props.singleNftImage.loading ? (
        <SingleImageLoader />
      ) : Object.keys(props.singleNftImage.data).length > 0 &&
        props.singleNftImage.data.nft_collection_item ? (
        <>
          <div className="pages-padding">
            {auth.authStatus && !props.singleNftImage.loading ? (
              props.singleNftImage.data.nft_collection_item.recent_buyer_id ===
              auth.userId ? (
                <div className="single-page-btn">
                  {/* {props.singleNftImage.data.nft_collection_item
                    .edit_btn_status ? (
                    <Link
                      to={`/edit-item/${props.singleNftImage.data.nft_collection.nft_collection_unique_id}/${props.singleNftImage.data.nft_collection_item.nft_collection_item_unique_id}`}
                      className="default-btn border-radius-5"
                    >
                      Edit
                    </Link>
                  ) : null} */}
                  {props.singleNftImage.data.nft_collection_item
                    .list_btn_status ? (
                    <Link
                      to={`/sell-item/${props.singleNftImage.data.nft_collection.contract_address}/${props.singleNftImage.data.nft_collection_item.token_id}`}
                      className="default-btn two border-radius-5 "
                    >
                      Sell
                    </Link>
                  ) : null}
                  {props.singleNftImage.data.nft_collection_item
                    .cancel_list_btn_status == 1 ? (
                    <button
                      className="default-btn two border-radius-5"
                      onClick={() => handleCancelListingOpen()}
                    >
                      Cancel lisiting
                    </button>
                  ) : null}
                </div>
              ) : null
            ) : null}
            <div className="item-details-area">
              <div className="container mt-4">
                {props.singleNftImage.loading
                  ? null
                  : props.singleNftImage.data.nft_collection_item && (
                      <div className="row">
                        <div className="col-12 single-details-mobile">
                          <div className="right-head mb-4 row">
                            <div className="page-title col-12 col-lg-6">
                              <h4>
                                {
                                  props.singleNftImage.data.nft_collection_item
                                    .name
                                }
                              </h4>
                              <div className="verified-icon-sec">
                                <Link to={`/collection/${contract_address}`}>
                                  {
                                    props.singleNftImage.data.nft_collection
                                      .name
                                  }
                                </Link>
                                {props.singleNftImage.data.nft_collection
                                  .is_verified == 1 && (
                                  <img
                                    className="verified-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/icons/verified.png"
                                    }
                                    alt="Verified Icon"
                                  />
                                )}
                              </div>
                            </div>

                            <div className="head-icon d-flex single-head-icon">
                              {/* <CustomTooltip title="refresh" placement="bottom">
                        <a href="#" className="btn-header">
                          <RefreshOutlinedIcon />
                        </a>
                      </CustomTooltip> */}
                              {props.singleNftImage.data.nft_collection_item
                                .external_link != "" && (
                                <>
                                  <CustomTooltip
                                    title="view"
                                    placement="bottom"
                                  >
                                    <a
                                      href={
                                        props.singleNftImage.data
                                          .nft_collection_item.external_link
                                      }
                                      target="_blank"
                                      className="btn-header"
                                    >
                                      <OpenInNewOutlinedIcon />
                                    </a>
                                  </CustomTooltip>
                                </>
                              )}

                              <a
                                href="javascript:void(0)"
                                className="btn-header btn-dropdown-icon"
                              >
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="link"
                                    id="dropdown-basic"
                                  >
                                    <ShareOutlinedIcon />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">
                                      <i class="far fa-copy"></i>
                                      Copy Link
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-1">
                                      <i class="fab fa-facebook-square"></i>
                                      Share on Facebook
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-1">
                                      <i class="fab fa-twitter"></i>
                                      Share on Twitter
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </a>
                              {auth.authStatus &&
                              !props.singleNftImage.loading ? (
                                props.singleNftImage.data.nft_collection_item
                                  .recent_buyer_id === auth.userId ? (
                                  <CustomTooltip
                                    title="transfer"
                                    placement="bottom"
                                  >
                                    <button
                                      className="btn-header"
                                      onClick={() => handleTransferModalOpen()}
                                    >
                                      <CardGiftcardIcon />
                                    </button>
                                  </CustomTooltip>
                                ) : null
                              ) : null}

                              <CustomTooltip title="report" placement="bottom">
                                <a
                                  href="javascript:void(0)"
                                  className="btn-header"
                                  onClick={() => handleReportOpen()}
                                >
                                  <FlagOutlinedIcon />
                                </a>
                              </CustomTooltip>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <FancyBox>
                            <div className="item-details-left-side pr-20">
                              <div className="left-single-card">
                                <div className="card-heart-head">
                                  {favouriteStatus ? (
                                    <i
                                      className="fa fa-heart mx-2"
                                      onClick={
                                        auth.authStatus
                                          ? () => handleLike()
                                          : () =>
                                              handleConnector(
                                                loginConnectors.find(
                                                  (connectors) =>
                                                    connectors.name ==
                                                    "MetaMask"
                                                ).connectorFunction
                                              )
                                      }
                                    ></i>
                                  ) : (
                                    <i
                                      className="far fa-heart mx-2"
                                      onClick={
                                        auth.authStatus
                                          ? () => handleLike()
                                          : () =>
                                              handleConnector(
                                                loginConnectors.find(
                                                  (connectors) =>
                                                    connectors.name ==
                                                    "MetaMask"
                                                ).connectorFunction
                                              )
                                      }
                                    ></i>
                                  )}
                                  {favouritesCount}
                                </div>
                                <div className="item-details-img">
                                  <a
                                    href={
                                      props.singleNftImage.data
                                        .nft_collection_item.picture != ""
                                        ? props.singleNftImage.data
                                            .nft_collection_item.picture
                                        : configuration.get(
                                            "configData.item_placholder"
                                          )
                                    }
                                    data-fancybox
                                    data-caption={`<h3 class="caption-title">${props.singleNftImage.data.nft_collection.name}</h3> <p class="caption-text">${props.singleNftImage.data.nft_collection_item.name}</p>`}
                                  >
                                    <img
                                      src={
                                        props.singleNftImage.data
                                          .nft_collection_item.picture != ""
                                          ? props.singleNftImage.data
                                              .nft_collection_item.picture
                                          : configuration.get(
                                              "configData.item_placholder"
                                            )
                                      }
                                      alt="Images"
                                    />
                                  </a>
                                </div>
                              </div>
                              <div className="owned-fav display-info">
                                <p className="mb-0">
                                  Owned by{" "}
                                  <Link
                                    to={
                                      props.singleNftImage.data
                                        .nft_collection_item.recent_buyer_id ===
                                      auth.userId
                                        ? `/profile`
                                        : `/account/${props.singleNftImage.data.nft_collection_item.buyer_unique_id}`
                                    }
                                  >
                                    {
                                      props.singleNftImage.data
                                        .nft_collection_item
                                        .buyer_wallet_address
                                    }
                                  </Link>
                                </p>
                                <p
                                  className="info-heart"
                                  onClick={() => handlefavouriteUsersOpen()}
                                >
                                  <i className="ri-heart-line"></i>
                                  {favouritesCount} Favorites
                                </p>
                              </div>

                              <div className="single-details-sec">
                                <div className="des-sec">
                                  <div className="desc-sec-wrapper">
                                    <h2 className="inline-style">
                                      <SubjectOutlinedIcon />
                                      Description
                                    </h2>
                                    {/* <KeyboardArrowDownIcon /> */}
                                  </div>
                                  <p>
                                    {props.singleNftImage.data
                                      .nft_collection_item.description != "" &&
                                    props.singleNftImage.data
                                      .nft_collection_item.description != null
                                      ? props.singleNftImage.data
                                          .nft_collection_item.description
                                      : "No description"}
                                  </p>
                                </div>
                                <Accordion
                                  defaultActiveKey={activeId}
                                  className="single-collection-properties"
                                >
                                  {props.singleNftImage.data.nft_collection_item
                                    .properties_formatted.length > 0 && (
                                    <Card className="rounded-lg  border-0 accordion-style">
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey="0"
                                        onClick={() => toggleActive("0")}
                                        className={
                                          activeId === "0" ? "active" : null
                                        }
                                      >
                                        <div className="inline-style">
                                          <LabelOutlinedIcon />
                                          Properties
                                        </div>

                                        <KeyboardArrowDownIcon />
                                      </Accordion.Toggle>
                                      <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                          <div className="sub-content">
                                            <div className="single-page-properties">
                                              {props.singleNftImage.data
                                                .nft_collection_item
                                                .properties_formatted.length > 0
                                                ? props.singleNftImage.data.nft_collection_item.properties_formatted.map(
                                                    (property, index) => (
                                                      <div
                                                        className="properties-panel text-center"
                                                        onClick={() =>
                                                          handlePropertySelect(
                                                            property
                                                          )
                                                        }
                                                      >
                                                        <CustomTooltip
                                                          title={property.name}
                                                          placement="top"
                                                        >
                                                          <h2>
                                                            {property.name}
                                                          </h2>
                                                        </CustomTooltip>
                                                        <CustomTooltip
                                                          title={property.value}
                                                          placement="top"
                                                        >
                                                          <p>
                                                            {property.value}
                                                          </p>
                                                        </CustomTooltip>
                                                      </div>
                                                    )
                                                  )
                                                : null}
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  )}

                                  {props.singleNftImage.data.nft_collection_item
                                    .description && (
                                    <Card className="rounded-lg border-0 accordion-style">
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey="1"
                                        onClick={() => toggleActive("1")}
                                        className={
                                          activeId === "1" ? "active" : null
                                        }
                                      >
                                        <div className="inline-style">
                                          <VerticalSplitOutlinedIcon />
                                          About{" "}
                                          {
                                            props.singleNftImage.data
                                              .nft_collection.name
                                          }
                                        </div>
                                        <KeyboardArrowDownIcon />
                                      </Accordion.Toggle>
                                      <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                          <p className="text-muted">
                                            {
                                              props.singleNftImage.data
                                                .nft_collection.description
                                            }
                                          </p>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  )}
                                  <Card className="rounded-lg  border-0 accordion-style">
                                    <Accordion.Toggle
                                      as={Card.Header}
                                      eventKey="2"
                                      onClick={() => toggleActive("2")}
                                      className={
                                        activeId === "2" ? "active" : null
                                      }
                                    >
                                      <div className="inline-style">
                                        <BallotOutlinedIcon />
                                        Details
                                      </div>
                                      <KeyboardArrowDownIcon />
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                      <Card.Body>
                                        <div className="sub-content">
                                          {props.singleNftImage.data
                                            .nft_collection_item
                                            .user_wallet_address != "" && (
                                            <>
                                              <div className="single-page-details">
                                                <p>Contract Address</p>
                                                {configuration.get(
                                                  "configData.rpc_url"
                                                ) != "" ? (
                                                  <>
                                                    <a
                                                      href={`${configuration.get(
                                                        "configData.block_explorer_urls"
                                                      )}/address/${contract_address}`}
                                                      target="_blank"
                                                    >
                                                      {String(
                                                        contract_address
                                                      ).substring(0, 5)}
                                                      ...
                                                      {String(
                                                        contract_address
                                                      ).substring(
                                                        contract_address.length -
                                                          4
                                                      )}
                                                    </a>
                                                  </>
                                                ) : (
                                                  <p>
                                                    {String(
                                                      contract_address
                                                    ).substring(0, 5)}
                                                    ...
                                                    {String(
                                                      contract_address
                                                    ).substring(
                                                      contract_address.length -
                                                        4
                                                    )}
                                                  </p>
                                                )}
                                              </div>
                                            </>
                                          )}
                                          {props.singleNftImage.data
                                            .nft_collection.category_name !=
                                            "" && (
                                            <>
                                              <div className="single-page-details">
                                                <p>Category</p>
                                                <h2>
                                                  {
                                                    props.singleNftImage.data
                                                      .nft_collection
                                                      .category_name
                                                  }
                                                </h2>
                                              </div>
                                            </>
                                          )}

                                          <div className="single-page-details">
                                            <p>Blockchain</p>
                                            <h2>
                                              {configuration.get(
                                                "configData.native_currency_name"
                                              )}
                                            </h2>
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </div>
                            </div>
                          </FancyBox>
                        </div>

                        <div className="col-lg-6">
                          <div className="single-details-desktop">
                            <div className="right-head mb-4">
                              <div className="page-title">
                                <h4>
                                  {
                                    props.singleNftImage.data
                                      .nft_collection_item.name
                                  }
                                </h4>
                                <div className="verified-icon-sec">
                                  <Link to={`/collection/${contract_address}`}>
                                    {
                                      props.singleNftImage.data.nft_collection
                                        .name
                                    }
                                  </Link>
                                  {props.singleNftImage.data.nft_collection
                                    .is_verified == 1 && (
                                    <img
                                      className="verified-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/images/icons/verified.png"
                                      }
                                      alt="Verified Icon"
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="head-icon d-flex single-head-icon">
                                {/* <CustomTooltip title="refresh" placement="bottom">
                          <a href="#" className="btn-header">
                            <RefreshOutlinedIcon />
                          </a>
                        </CustomTooltip> */}
                                {props.singleNftImage.data.nft_collection_item
                                  .external_link != "" && (
                                  <CustomTooltip
                                    title="view"
                                    placement="bottom"
                                  >
                                    <a
                                      href={
                                        props.singleNftImage.data
                                          .nft_collection_item.external_link
                                      }
                                      target="_blank"
                                      className="btn-header"
                                    >
                                      <OpenInNewOutlinedIcon />
                                    </a>
                                  </CustomTooltip>
                                )}

                                <a
                                  href="javascript:void(0)"
                                  className="btn-header btn-dropdown-icon"
                                >
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="link"
                                      id="dropdown-basic"
                                    >
                                      <ShareOutlinedIcon />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <CopyToClipboard
                                        text={
                                          window.location.origin +
                                          props.location.pathname
                                        }
                                        onCopy={onCopy}
                                      >
                                        <Dropdown.Item href="#">
                                          <i class="far fa-copy"></i>
                                          Copy Link
                                        </Dropdown.Item>
                                      </CopyToClipboard>

                                      <Dropdown.Item>
                                        <FacebookShareButton
                                          url={
                                            window.location.origin +
                                            props.location.pathname
                                          }
                                          quote={
                                            window.location.origin +
                                            props.location.pathname
                                          }
                                          className="Demo__some-network__share-button"
                                        >
                                          <i class="fab fa-facebook-square"></i>
                                          Share on Facebook
                                        </FacebookShareButton>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <TwitterShareButton
                                          url={
                                            window.location.origin +
                                            props.location.pathname
                                          }
                                          className="Demo__some-network__share-button"
                                        >
                                          <i class="fab fa-twitter"></i>
                                          Share on Twitter
                                        </TwitterShareButton>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </a>
                                {auth.authStatus &&
                                !props.singleNftImage.loading ? (
                                  props.singleNftImage.data.nft_collection_item
                                    .recent_buyer_id === auth.userId ? (
                                    <CustomTooltip
                                      title="transfer"
                                      placement="bottom"
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        className="btn-header"
                                        onClick={() =>
                                          handleTransferModalOpen()
                                        }
                                      >
                                        <CardGiftcardIcon />
                                      </a>
                                    </CustomTooltip>
                                  ) : null
                                ) : null}
                                <CustomTooltip
                                  title="report"
                                  placement="bottom"
                                >
                                  <a
                                    href="javascript:void(0)"
                                    className="btn-header"
                                    onClick={() => handleReportOpen()}
                                  >
                                    <FlagOutlinedIcon />
                                  </a>
                                </CustomTooltip>
                              </div>
                            </div>
                            <div className="owned-fav">
                              <p className="mb-0">
                                Owned by{" "}
                                <Link
                                  to={
                                    props.singleNftImage.data
                                      .nft_collection_item.recent_buyer_id ===
                                    auth.userId
                                      ? `/profile`
                                      : `/account/${props.singleNftImage.data.nft_collection_item.buyer_unique_id}`
                                  }
                                >
                                  {
                                    props.singleNftImage.data
                                      .nft_collection_item.buyer_wallet_address
                                  }
                                </Link>
                              </p>
                              <p
                                className="info-heart"
                                onClick={() => handlefavouriteUsersOpen()}
                              >
                                <i className="ri-heart-line"></i>
                                {favouritesCount} Favorites
                              </p>
                            </div>
                          </div>

                          {(props.singleNftImage.data.nft_collection_item
                            .is_listed == 1 &&
                            props.singleNftImage.data.nft_collection_item
                              .amount > 0 &&
                            props.singleNftImage.data.nft_collection_item
                              .is_listing_expired != 1) ||
                          (props.singleNftImage.data.nft_collection_item
                            .list_expire_formatted &&
                            props.singleNftImage.data.nft_collection_item
                              .is_listing_started == 1 &&
                            props.singleNftImage.data.nft_collection_item
                              .is_listing_expired != 1) ||
                          props.singleNftImage.data.nft_collection_item
                            .recent_buyer_id != auth.userId ? (
                            <>
                              <div className="item-details-dsce mt-4 mt-lg-0">
                                <div className="single-page-right-content price-view">
                                  {props.singleNftImage.data.nft_collection_item
                                    .list_expire_formatted &&
                                    props.singleNftImage.data
                                      .nft_collection_item.is_listing_started ==
                                      1 &&
                                    props.singleNftImage.data
                                      .nft_collection_item.is_listing_expired !=
                                      1 && (
                                      <div className="sell-timing">
                                        {/* <p>
                                          Sale ends{" "}
                                          {
                                            props.singleNftImage.data
                                              .nft_collection_item
                                              .list_expire_formatted
                                          }{" "}
                                        </p> */}
                                        <h5>Sale ends in </h5>
                                        <DateCountdown
                                          dateFrom={new Date()}
                                          dateTo={
                                            props.singleNftImage.data
                                              .nft_collection_item
                                              .list_expire_at
                                          }
                                          numberOfFigures={5}
                                          // callback={() => getCurrentOwner()}
                                        />
                                      </div>
                                    )}
                                  {props.singleNftImage.data.nft_collection_item
                                    .is_listed == 1 &&
                                    props.singleNftImage.data
                                      .nft_collection_item
                                      .list_start_formatted &&
                                    props.singleNftImage.data
                                      .nft_collection_item.amount > 0 &&
                                    props.singleNftImage.data
                                      .nft_collection_item.is_listing_started !=
                                      1 && (
                                      <div className="sell-timing">
                                        {/* <p>
                                          Sale starts at{" "}
                                          {
                                            props.singleNftImage.data
                                              .nft_collection_item
                                              .list_start_formatted
                                          }{" "}
                                        </p> */}
                                        <h5>Sale Starts in </h5>
                                        <DateCountdown
                                          dateFrom={new Date()}
                                          dateTo={
                                            props.singleNftImage.data
                                              .nft_collection_item.list_start_at
                                          }
                                          numberOfFigures={5}
                                          // callback={() => getCurrentOwner()}
                                        />
                                      </div>
                                    )}

                                  <div className="price-view-sec-wrapper">
                                    {props.singleNftImage.data
                                      .nft_collection_item.is_listed == 1 &&
                                    props.singleNftImage.data
                                      .nft_collection_item.amount > 0 &&
                                    props.singleNftImage.data
                                      .nft_collection_item.is_listing_expired !=
                                      1 ? (
                                      <>
                                        <div className="price-view-sec">
                                          <div
                                            className={`single-image-price-wrapper`}
                                          >
                                            <h5 className="m-0">
                                              Current price
                                            </h5>
                                            <div className="etherum-img">
                                              {props.singleNftImage.data.nft_collection_item.list_amount_type == 1 ? (
                                                <>
                                                  <img src={priceImage} />
                                                  <h3>
                                                  {Number(
                                                    props.singleNftImage.data
                                                    .nft_collection_item.amount
                                                  ).toLocaleString(undefined, {
                                                    maximumFractionDigits: 2,
                                                  })}{" "}{configuration.get("configData.native_currency_symbol")}
                                                  {price.usdConvertedPrice !=
                                                    null && (
                                                    <span className="converted_price right">
                                                      ( ≉{" "}
                                                      <i
                                                        className="fa fa-usd"
                                                        aria-hidden="true"
                                                      ></i>{" "}
                                                      {Number(
                                                        props.singleNftImage.data
                                                          .nft_collection_item
                                                          .amount *
                                                          price.usdConvertedPrice
                                                      ).toLocaleString(
                                                        undefined,
                                                        {
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}{" "}
                                                      {price.denomination})
                                                    </span>
                                                  )}
                                                  </h3>
                                                </>
                                              ) : (
                                                <>
                                                  <img src={stableTokenLight} />
                                                  <h3>
                                                    {Number(
                                                      props.singleNftImage.data
                                                      .nft_collection_item.stable_token_amount
                                                    ).toLocaleString(undefined, {
                                                      maximumFractionDigits: 2,
                                                    })}{" "}{configuration.get("configData.stable_toke_symbol")}
                                                  </h3>
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                    <div className="">
                                      {props.singleNftImage.data
                                        .nft_collection_item.recent_buyer_id !=
                                        auth.userId && (
                                        <>
                                          <Button
                                            className="default-btn two border-radius-5 px-4 py-3 make-offer-btn"
                                            disabled={
                                              makeOfferButtonContent != "" ||
                                              props.singleNftImage.data.nft_collection.is_verified == 0
                                                ? true
                                                : false
                                            }
                                            onClick={() =>
                                              auth.authStatus
                                                ? handleMakeOfferOpen()
                                                : handleConnector(
                                                    loginConnectors.find(
                                                      (connectors) =>
                                                        connectors.name ==
                                                        "MetaMask"
                                                    ).connectorFunction
                                                  )
                                            }
                                          >
                                            {makeOfferButtonContent != ""
                                              ? makeOfferButtonContent
                                              : "Make Offer"}
                                          </Button>
                                        </>
                                      )}

                                      {props.singleNftImage.data
                                        .nft_collection_item.buy_btn_status ==
                                        1 &&
                                        props.singleNftImage.data
                                          .nft_collection_item
                                          .is_listing_started == 1 &&
                                        props.singleNftImage.data
                                          .nft_collection_item
                                          .is_listing_expired != 1 && (
                                          <Button
                                            className="default-btn two border-radius-5 px-4 py-3"
                                            disabled={
                                              props.singleNftImage.data
                                                .nft_collection_item.is_sold ==
                                                1 || buttonContent != ""
                                                ? true
                                                : false
                                            }
                                            onClick={() =>
                                              auth.authStatus
                                                ? handleUnReviewedOpen()
                                                : handleConnector(
                                                    loginConnectors.find(
                                                      (connectors) =>
                                                        connectors.name ==
                                                        "MetaMask"
                                                    ).connectorFunction
                                                  )
                                            }
                                          >
                                            {buttonContent != ""
                                              ? buttonContent
                                              : "Buy now"}
                                          </Button>
                                        )}
                                    </div>
                                    {props.singleNftImage.data.nft_collection.is_verified == 0 && (
                                      <div className="d-flex unverified-warning-sec">
                                        <WarningIcon
                                          className="unverified-warning-icon"
                                        />
                                        <p className="unverified-warning">This is a Un-verified collection. Only when this collection is verified will 
                                          you be able to Buy-Sell-Trade it. If you are the owner of this collection, 
                                          please <a target="_blank" href ="/page/contact">contact us</a>
                                        </p>
                                      </div>
                                    )}
                                    
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}

                          {/* use SinglePageActivityTableLoader for loading event */}
                          {props.bidOfferNftItemList.loading ? (
                            <>
                              <div className="mt-4">
                                <SinglePageActivityTableLoader />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="item-activity single-details-sec">
                                <Accordion defaultActiveKey={"5"}>
                                  <Card className="rounded-lg  border-0 accordion-style border-none">
                                    <Accordion.Toggle
                                      as={Card.Header}
                                      eventKey="5"
                                      onClick={() => toggleActive("5")}
                                    >
                                      <div className="inline-style">
                                        <SwapVertOutlinedIcon />
                                        Offers
                                      </div>
                                      <KeyboardArrowDownIcon />
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="5">
                                      <OfferListTable
                                        offerList={
                                          props.bidOfferNftItemList.data
                                        }
                                        singleNftImage={
                                          props.singleNftImage.data
                                        }
                                        getCurrentOwner={getCurrentOwner}
                                        approveOfferSave={approveOfferSave}
                                        cancelOfferSave={cancelOfferSave}
                                        cancelOfferBtnContent={
                                          cancelOfferBtnContent
                                        }
                                        setCancelOfferBtnContent={
                                          setCancelOfferBtnContent
                                        }
                                        acceptOfferBtnContent={
                                          acceptOfferBtnContent
                                        }
                                        setAcceptOfferBtnContent={
                                          setAcceptOfferBtnContent
                                        }
                                      />
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                <div className="item-activity single-details-sec">
                  <Accordion defaultActiveKey={activeId}>
                    <Card className="rounded-lg  border-0 accordion-style border-none">
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="3"
                        onClick={() => toggleActive("3")}
                        className={activeId === "3" ? "active" : null}
                      >
                        <div className="inline-style">
                          <SwapVertOutlinedIcon />
                          Item Activity
                        </div>
                        <KeyboardArrowDownIcon />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="3">
                        <>
                          <div className="item-activity-filter-sec">
                            <div className="p-3 ">
                              <CustomSelect
                                value={priceSort}
                                handleChange={handlePriceSortChange}
                                items={[
                                  "Listing",
                                  "Sale",
                                  "Cancel",
                                  "Transfer",
                                ]}
                                placeHolder={"Filter"}
                                multiple={true}
                              />
                            </div>
                            <div className="item-activity-filter">
                              {priceSort.map((price, index) => (
                                <div className="properties-panel text-center">
                                  <h2 className="m-0">
                                    {price}
                                    <CloseIcon
                                      className="price-sort-close"
                                      onClick={() =>
                                        handleRemovePriceSort(price)
                                      }
                                    />
                                  </h2>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="">
                            {props.nftItemsPaymentList.loading ? (
                              <>
                                <div className="m-3">
                                  <SinglePageActivityTableLoader />
                                </div>
                              </>
                            ) : props.nftItemsPaymentList.data
                                .nft_item_payments &&
                              props.nftItemsPaymentList.data.nft_item_payments
                                .length > 0 ? (
                              <Paper
                                style={{ width: "100%", overflow: "hidden" }}
                              >
                                <CustomInfiniteScroll
                                  fetchAction={loadMoreItemActivityStart}
                                  fetchData={props.nftItemsPaymentList}
                                  fetchedData={
                                    props.nftItemsPaymentList.data
                                      .nft_item_payments
                                  }
                                  skip={
                                    props.nftItemsPaymentList.data
                                      .nft_item_payments.length
                                  }
                                  take={6}
                                  skippedFetch={true}
                                  total={props.nftItemsPaymentList.data.total}
                                  dispatchData={{
                                    nft_collection_item_token_id: token_id,
                                    payment_type: priceSort,
                                  }}
                                  useOffset={60}
                                >
                                  <TableContainer
                                    style={{
                                      maxHeight: 440,
                                      backgroundColor: "rgb(251,253,255)",
                                    }}
                                  >
                                    <Table
                                      stickyHeader
                                      aria-label="sticky table"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell align="center">
                                            Event
                                          </TableCell>
                                          <TableCell align="center">
                                            Price
                                          </TableCell>
                                          <TableCell align="center">
                                            From
                                          </TableCell>
                                          <TableCell align="center">
                                            To
                                          </TableCell>
                                          <TableCell align="center">
                                            Date
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <Activitytable
                                          paymentData={
                                            props.nftItemsPaymentList.data
                                              .nft_item_payments
                                          }
                                          auth={auth}
                                          classes={"mb-3"}
                                        />
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </CustomInfiniteScroll>
                              </Paper>
                            ) : (
                              <NoDataFound />
                            )}
                          </div>
                          {/* <Activitytable
                          paymentData={
                            props.nftItemsPaymentList.data.nft_item_payments
                          }
                          auth={auth}
                          classes={"mb-3"}
                        /> */}
                        </>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                {props.itemsList.loading ? (
                  <SingleImageItemListLoader />
                ) : (
                  props.itemsList.data.nft_collection_items.filter(
                    (collectionitem) => collectionitem.token_id != token_id
                  ).length > 1 && (
                    <div className="more-collection single-details-sec">
                      <Accordion defaultActiveKey={activeId}>
                        <div className="rounded-lg  border-0 no-border">
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey="4"
                            onClick={() => toggleActive("4")}
                            className={
                              activeId === "4"
                                ? "active custom-card-header"
                                : "custom-card-header"
                            }
                          >
                            <div className="inline-style">
                              <ViewModuleOutlinedIcon />
                              More from this collection
                            </div>
                            <KeyboardArrowDownIcon />
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="4">
                            <>
                              <div className="accordion-flow">
                                <div className="row no-wrap g-3">
                                  {props.itemsList.data.nft_collection_items
                                    .filter(
                                      (collectionitem) =>
                                        collectionitem.token_id != token_id
                                    )
                                    .map((item, index) => (
                                      <CollectionCard
                                        data={item}
                                        useFlex={true}
                                        key={index}
                                      />
                                    ))}
                                </div>
                              </div>
                              {props.singleNftImage.data.nft_collection && (
                                <div className="view-btn text-center my-3">
                                  <Link
                                    to={`/collection/${props.singleNftImage.data.nft_collection.contract_address}`}
                                    className=" default-btn two border-radius-5 mt-3"
                                  >
                                    View collection
                                  </Link>
                                </div>
                              )}
                            </>
                          </Accordion.Collapse>
                        </div>
                      </Accordion>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          {props.singleNftImage.loading
            ? null
            : props.singleNftImage.data.nft_collection_item && (
                <ReportModal
                  open={open}
                  handleReportClose={handleReportClose}
                  contract_address={contract_address}
                  token_id={token_id}
                />
              )}
          {cancelListingModal && (
            <CancelListingModal
              open={cancelListingModal}
              handleCancelListingClose={handleCancelListingClose}
              singleNftImage={props.singleNftImage}
              auth={auth}
              signData={signData}
              setSignData={setSignData}
              activeAccordion={activeCancelListingAccordion}
              toggleAccordion={toggleActiveAccordion}
              cancelBlockchain={cancelBlockchain}
              loadingButtonContent={cancelListingButtonContent}
            />
          )}

          {unReviewedModal && (
            <UnReviewedModal
              open={unReviewedModal}
              handleUnReviewedClose={handleUnReviewedClose}
              singleNftImage={props.singleNftImage}
              priceFormatted={usdPrice}
              buttonContent={buttonContent}
              sendMoneyToCreator={sendMoneyToCreator}
              priceSelect={priceSelect}
              setPriceSelect={setPriceSelect}
            />
          )}

          {makeOfferModal && (
            <MakeOfferModal
              open={makeOfferModal}
              handleMakeOfferClose={handleMakeOfferClose}
              singleNftImage={props.singleNftImage}
              makeOfferButtonContent={makeOfferButtonContent}
              setMakeOfferButtonContent={setMakeOfferButtonContent}
              contract_address={contract_address}
              token_id={token_id}
              getCurrentOwner={getCurrentOwner}
              makeOfferSave={makeOfferSave}
            />
          )}

          <FavoritedByModal
            open={favoiriteUsersModal}
            handlefavouriteUsersClose={handlefavouriteUsersClose}
            itemFavouriteUsers={props.itemFavouriteUsers}
            auth={auth}
          />

          {transferModal && (
            <TransferModal
              open={transferModal.status}
              handleClose={handleTransferModalClose}
              singleNftImage={props.singleNftImage}
              activeAccordion={activeTransferAccordion}
              toggleAccordion={toggleActiveTransferAccordion}
              signData={signData}
              setSignData={setSignData}
            />
          )}
        </>
      ) : props.singleNftImage.error ||
        props.itemsList.error ||
        props.nftItemsPaymentList.error ? (
        <PageNotFound />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  singleNftImage: state.nfts.singleItems,
  itemsList: state.nfts.moreFromCollection,
  nftItemsPaymentList: state.nfts.nftItemsPaymentList,
  itemFavouriteUsers: state.nfts.itemFavouriteUsers,
  favouriteSave: state.nfts.favouriteSave,
  buyNftItem: state.nfts.buyNftItem,
  transferNft: state.nfts.transferNft,
  bidOfferNftItemList: state.nfts.bidOfferNftItemList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleNftImage);
